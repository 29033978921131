<template>
  <div class="mainwrap banneredit" v-loading="loading">
    <div class="title">聯繫我們編輯</div>
    <div class="formwrap">
      <el-form
          :model="detailFrom"
          ref="detailFrom"
          label-width="150px"
          label-position="left"
          :rules="rules"
      >

        <el-form-item label="姓名">
          <el-input
              v-model="detailFrom.name"
              disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="電話">
          <el-input
              v-model="detailFrom.phoneNumber"
              disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="郵箱">
          <el-input
              v-model="detailFrom.email"
              disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="建議事項">
          <el-input
              type="textarea"
              v-model="detailFrom.proposal"
              disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="狀態">
          <el-select v-model="detailFrom.status" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="處理结果" prop="note">
          <el-input
              type="textarea"
              v-model="detailFrom.note"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap">
      <div class="btnlist">
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import {contactUsEdit} from "./api";
export default {
  data() {
    return {
      loading: false,
      detailId:'',
      options: [{
        value: 1,
        label: '已處理'
      }, {
        value: 0,
        label: '未處理'
      }],
      detailFrom:{
        name: "",
        phoneNumber:"",
        email:"",
        proposal:"",
        status: "",
        note:""
      },
      rules:{
        note:[{ required: true, message: "請輸入處理结果", trigger: "blur" }]
      }
    };
  },
  created() {
    let queryObj = this.$route.query || {};
    if (queryObj.type && queryObj.type === "edit" && queryObj.id) {
      this.detailId=queryObj.id
      this.getDetail()
    }
  },
  methods: {
    getDetail(){
      this.loading = true;
      api.contactUsDetail(this.detailId).then(res=>{
        if(res.systemCode===200){
          this.detailFrom = res.data;
          this.detailFrom.status=this.detailFrom.status==="已處理"? 1:0;
        }
        this.loading = false;
      })
    },
    // 提交
    onSubmit() {
      let dataFrom ={
        id: this.detailId,
        status: this.detailFrom.status,
        note:this.detailFrom.note
      }
      this.$refs["detailFrom"].validate(valid => {
        if (valid) {
          api.contactUsEdit(dataFrom).then(res=>{
            if(res.systemCode===200){
              this.$message.success('提交成功');
              this.$router.push({path:"/cms/contactus"});
            }else{
              // this.$message.error(res.message);
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消
    cancel() {
      this.$router.push({path:"/cms/contactus"});
    },
  },
};
</script>

<style lang="scss" scoped>
.banneredit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    width: 500px;
    .inlineblock {
      .el-input {
        width: 80%;
        margin-right: 10px;
      }
    }
  }
  .linkSearchBox{
    width: 500px;
    margin-bottom: 10px;
    .el-input {
      width: 250px;
      margin-right: 10px;
    }
  }
}
</style>
